<template>
  <view-item title="服务管理">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加服务</en-button>

      <button-ajax v-if="form.data.id" :method="operation.submit.click">提交</button-ajax>

      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">服务信息</en-dropdown-item>
          <en-dropdown-item command="customer">客户档案</en-dropdown-item>
          <en-dropdown-item command="vehicle">车辆档案</en-dropdown-item>
          <en-dropdown-item command="discard">单据废弃</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enocloud/business/opportunity/query' }"
        :props="{ start: 'preparedDateStart', end: 'preparedDateEnd' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
        :ref="setRef('manifest')"
      >
        <en-table-column label="客户名称" prop="customerName"></en-table-column>
        <en-table-column label="服务类型" prop="categoryKey.message"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="联系人手机">
            <en-input v-model="data.quickSearch"></en-input>
          </en-form-item>
          <en-form-item label="到期日期">
            <en-date-picker v-model="data.dueDate" class="w-full"></en-date-picker>
          </en-form-item>
          <en-form-item label="下次跟进日期">
            <en-date-picker
              v-model:start="data.nextFollowupDateStart"
              v-model:end="data.nextFollowupDateEnd"
              type="daterange"
              class="w-full"
            ></en-date-picker>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex gap-6 text-sm">
        <span>{{ form.data.vehicle?.plateNo }}</span>
        <span>{{ form.data.customer?.name }}/{{ form.data.customer?.cellphone }}</span>
        <span>{{ form.data.vehicle?.vehicleSpec.join('/') }}</span>
        <span>服务类型：{{ form.data.categoryKey?.message }}</span>
        <span>到期时间：{{ formatDate(form.data.dueDate) || '无' }}</span>
        <span>服务备注：{{ form.data.comment || '无' }}</span>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane label="服务信息" name="info">
                <en-scrollbar :height="height - 55">
                  <en-form :model="form.opportunityRecord.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading" class="w-1/2">
                    <en-form-item label="服务阶段" prop="type.code">
                      <select-maintain
                        v-model="form.opportunityRecord.data.type"
                        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['BORCTP']) }"
                        :props="{ label: 'message', value: '' }"
                        value-key="code"
                        class="w-full"
                        @change="form.opportunityRecord.type.change"
                      ></select-maintain>
                    </en-form-item>
                    <en-form-item label="跟进内容" prop="comment">
                      <en-input v-model="form.opportunityRecord.data.comment" type="textarea" :disabled="form.disabled"></en-input>
                    </en-form-item>
                    <en-form-item label="下次跟进" v-if="form.opportunityRecord.data.type?.code === 'FU'" prop="nextFollowupDate">
                      <en-date-picker v-model="form.opportunityRecord.data.nextFollowupDate" class="w-full" :disabledDate="form.disabledDate"></en-date-picker>
                    </en-form-item>
                  </en-form>
                </en-scrollbar>
              </en-tab-pane>

              <en-tab-pane label="跟进记录" name="record">
                <en-table :data="record.data" :loading="record.loading" :height="height - 55" pagination :paging="record.paging" :method="record.get">
                  <en-table-column label="跟进内容" prop="comment"></en-table-column>
                  <en-table-column label="服务阶段" prop="type.message"></en-table-column>
                  <en-table-column label="跟进日期" prop="preparedDatetime">
                    <template #default="{ row }: { row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordsDto'] }">
                      {{ formatDate(row.preparedDatetime) }}
                    </template>
                  </en-table-column>
                  <en-table-column label="下次跟进日期" prop="nextFollowupDate">
                    <template #default="{ row }: { row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordsDto'] }">
                      {{ formatDate(row.nextFollowupDate) }}
                    </template>
                  </en-table-column>
                </en-table>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <opportunity-detail v-model="detail.visible" :data="form.data"></opportunity-detail>

  <opportunity-dialog v-model="dialog.visible"></opportunity-dialog>

  <customer-record
    v-model="customerRecord.visible"
    :customer-data="form.data.customer"
    :vehicle-data="form.data.vehicle"
    @confirm="form.get"
  ></customer-record>

  <vehicle-record v-model="vehicleRecord.visible" :data="form.data.vehicle" @confirm="form.get"></vehicle-record>
</template>

<script lang="ts">
import { CustomerRecord, VehicleRecord } from '@enocloud/business'
import OpportunityDialog from '@client/components/opportunity-dialog.vue'
import OpportunityDetail from '@client/components/opportunity-detail.vue'
import { EnMessageBox } from '@enocloud/components'

const opportunityRecordDataInit = (): EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordsDto'] => {
  return {} as any
}

export default factory({
  components: { CustomerRecord, VehicleRecord, OpportunityDialog, OpportunityDetail },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.visible = true
          }
        },
        submit: {
          async click() {
            await this.form.submit()
            this.form.opportunityRecord.data = opportunityRecordDataInit()
            this.form.get()
            this.record.get()
            this.refs.manifest.table.method()
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'customer':
                this.customerRecord.visible = true
                break
              case 'vehicle':
                this.vehicleRecord.visible = true
                break
              case 'discard':
                await EnMessageBox.confirm('是否确认废弃单据', '提示')
                await this.form.discard()
                this.form.init()
                this.refs.manifest.table.method()
                return
            }
          }
        }
      },
      manifest: {
        row: {
          async click(row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityQueryDto']) {
            this.form.data.id = row.id
            await this.form.get()
            this.record.get()
          }
        }
      },
      tabs: {
        active: 'info'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/:opportunityId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/business/opportunity/record',
            loading: true,
            validate: true,
            params(params) {
              params.payload = {
                businessOpportunity: this.form.data,
                ...this.form.opportunityRecord.data
              }
            }
          },
          discard: {
            action: 'GET /enocloud/business/opportunity/:opportunityId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        disabledDate(time: Date) {
          return time.getTime() < Date.now()
        },
        computed: {
          disabled() {
            return this.opportunity.data.status?.code === 'D'
          }
        },
        rules: {
          'type.code': [{ required: true, message: '请选择服务阶段' }],
          comment: [{ required: true, message: '请填写跟进内容' }],
          nextFollowupDate: [{ required: true, message: '请选择跟进日期' }],
        },
        children: {
          opportunityRecord: {
            data: opportunityRecordDataInit(),
            type: {
              change() {
                const { type } = this.form.opportunityRecord.data
                if (type?.code === 'SC' || type?.code === 'FL') {
                  this.form.opportunityRecord.data.nextFollowupDate = ''
                }
              }
            }
          }
        }
      },
      record: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/record/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { businessOpportunityId: this.form.data.id }
            }
          }
        }
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      dialog: {
        visible: false
      },
      vehicleRecord: {
        visible: false
      },
      customerRecord: {
        visible: false
      }
    }
  }
})
</script>
